import React from 'react';
import Layout from '../components/layout';
import ComponentFormater from './component-formater';
import LazyLoad from 'react-lazyload';
import MetadataComponent from 'src/components/metadata';
import { getObjectWithLanguage } from 'root/_ultility';
const setting = require('src/data/setting.json');
const siteInfo = setting.find((item: any) => item.name === 'site_info');

interface IPageProps {
    pageContext: any;
}

class PageTemplate extends React.Component<IPageProps, {}> {
    constructor(props: IPageProps) {
        super(props);
    }

    componentDidMount() {
        this.handleCss();
    }
 
    handleCss() {
        document.documentElement.style.setProperty('--color-primary', siteInfo.params.sitePrimaryColor);
        document.documentElement.style.setProperty('--color-secondary', siteInfo.params.siteSecondaryColor);
        document.documentElement.style.setProperty('--color-tertiary', siteInfo.params.siteTertiaryColor);
        document.documentElement.style.setProperty('--color-primary-text', siteInfo.params.siteColorPrimaryText);
        document.documentElement.style.setProperty('--color-background', siteInfo.params.siteColorBackGround);
    }

    public render() {
        const { pageContext } = this.props;
        let { contentRow } = pageContext.data;

        if (typeof contentRow === 'undefined') {
            contentRow = [];
        }

        return (
            <>
                <MetadataComponent metadata={getObjectWithLanguage(pageContext.metadata)} />

                <Layout config={pageContext.layout} isHomePage={pageContext.isHomePage}>
                    <div style={{ minHeight: '466px' }} className={`page-${pageContext.name}`}>
                        {contentRow.length > 0 &&
                            contentRow.map((item: any, i: number) => {
                                return <LazyLoad classNamePrefix={`lazywrapper-${item.relations.id}`} height={400} offset={100} key={i}>
                                    <ComponentFormater layout={pageContext.layout} dataModule={item} />
                                </LazyLoad>;
                            })
                        }
                    </div>
                </Layout>
            </>
        );
    }
}

export default PageTemplate;
